<template>
  <v-container class="mt-5 mb-15 px-10" fluid>
    <PageHeader header-text="API Tokens">
      <template #subheader>
        View & manage API Token of this Account
      </template>
    </PageHeader>

    <div class="accounts-box-head mt-10 mb-3">
      <div
        v-if="apiUrl"
        class="mt-5"
      >
        API URL: {{ apiUrl }}
      </div>
      <div
        v-if="$store.getters['user/account'] && $store.getters['user/account'].accountId"
        class="mt-5"
      >
        Account ID: {{ $store.getters["user/account"].accountId }}
      </div>

      <v-card-actions
        v-if="isLoaded"
        class="d-flex align-center pa-0 mt-3"
      >
        <div
          v-if="api_token"
          class="pr-4"
        >
          API Token : {{ api_token.token_display }}
        </div>
        <v-btn
          v-if="api_token && api_token.token"
          v-clipboard="api_token.token"
          class="custom-button custom-button--gray px-9"
          height="34px"
          depressed
          @click="toggleToken"
        >
          <span class="id-icon">
            <v-btn color="#2b84eb" icon small>
              <v-icon v-if="showToken" color="#2b84eb" size="18">mdi-eye-off-outline</v-icon>
              <v-icon v-else color="#2b84eb" size="18">$eye</v-icon>
            </v-btn>
          </span>
        </v-btn>
        <v-btn
          v-if="api_token && api_token.token !== undefined"
          class="custom-button custom-button--gray px-15"
          height="34px"
          depressed
          @click="deleteToken()"
        >
          Delete
        </v-btn>
        <div v-if="!api_token">
          <p>
            There is no existing API Key for this Account.
            Please click <strong>"Generate"</strong> to generate a new API Key.
          </p>
          <v-btn
            class="custom-button custom-button--blue px-15"
            height="34px"
            depressed
            @click="generateToken"
          >
            Generate
          </v-btn>
        </div>
      </v-card-actions>
      <div class="mt-5">
        <a href="https://api-docs.pulsehealth.tech" target="_blank">Read our API documentation</a>
      </div>
    </div>

    <div class="add-contact-example my-3">
      <div class="label mt-10">
        Add Contact Example
      </div>
      <div class="my-3">
        <a href="https://api-docs.pulsehealth.tech/#fe6f92aa-2ac3-4358-a0f8-3e387eeb090f" target="_blank">POST endpoint documentation</a>
      </div>
      <pre class="json-view">{{ exampleData }}</pre>
    </div>
  </v-container>
</template>

<script>
import dayjs from "dayjs";
import PageHeader from "@/sharedComponents/PageHeader";

export default {
  name: "ApiTokensView",
  metaInfo: {
    title: 'API Token'
  },
  components: {
    PageHeader,
  },
  data() {
    return {
      api_token: null,
      isLoaded: false,
      showToken: false,
      apiUrl: process.env.VUE_APP_API_URL,
      exampleData: {
        "salutation": "Dr",
        "firstName": "Example",
        "middleName": "",
        "lastName": "Example",
        "credentials": "MD",
        "gender": "M",
        "npi": "1234567890",
        "specialty": "Oncology",
        "contactType": "HCP",
        "source": 1,
        "emails": [
          {
            "emailAddress": "example@email.com",
            "isSubscribed": true
          }
        ],
        "locations": [
          {
            "company": "The Night's Watch",
            "addressLine1": "1 Wall St.",
            "addressLine2": "Suite 100",
            "city": "New York",
            "state": "NY",
            "postalCode": "10001",
            "country": "USA"
          }
        ],
        "phones": [
          {
            "phone": "5165551212",
            "isPhoneSubscribed": true,
            "isSmsSubscribed": true,
            "isFax": false
          }
        ],
        "customFields": {},
        "isTest": false
      }
    };
  },
  computed: {
    selectedAccount() {
      return this.$store.getters['user/account'];
    }
  },
  watch: {
    showToken: {
      handler(val) {
        if (val) {
          this.api_token.token_display = this.api_token.token;
          return
        }
        this.api_token.token_display = this.api_token.token_hide;
      },
    },
  },
  async created() {
    await this.get_api_token();
    this.isLoaded = true;

    const customFields = this.selectedAccount.customFieldDefinition;
    if (customFields.contact) {
      const exampleCustomFields = {};
      customFields.contact.forEach(field => {
        let exampleValue = '';
        switch (field.type) {
          case 'boolean':
            exampleValue = true;
            break;
          case 'date':
            exampleValue = dayjs(new Date()).format('YYYY-MM-DD');
            break;
          case 'number':
            exampleValue = 12345;
            break;
          default:
            exampleValue = 'Example';
        }
        exampleCustomFields[field.fieldName] = exampleValue;
      });
      this.exampleData.customFields = exampleCustomFields;
    }
  },
  methods: {
    async get_api_token() {
      const resp = await this.$rest.api_tokens.get_resource();
      if (!resp.data) {
        return;
      }

      this.api_token = {
        ...resp.data,
        token_hide: this.maskToken(resp.data.token),
        token_display: this.maskToken(resp.data.token),
      };
    },
    toggleToken() {
      this.showToken = !this.showToken;
    },
    async generateToken() {
      const resp = await this.$rest.api_tokens.post_resource({});
      if (!resp.data) {
        return;
      }

      this.api_token = {
        ...resp.data,
        token_hide: this.maskToken(resp.data.token),
        token_display: this.maskToken(resp.data.token),
      };
    },
    async deleteToken() {
      try {
        await this.$rest.api_tokens.delete_resource(this.api_token.token);
        this.api_token = null;
      } catch (e) {
        this.$store.commit('snackbar/showMessage', {
          content: "Failed to delete the token, please try again.",
          color: "error"
        });
      }
    },
    maskToken(token) {
      return token[0] + '*'.repeat(token.length - 2) + token.slice(-1);
    },
  },
};
</script>

<style scoped>
.accounts-box-head,
.add-contact-example {
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

.add-contact-example .label {
  color: #000;
  font-size: 16px;
  font-weight: 600;
}

.add-contact-example .json-view {
  max-width: 640px;
  color: #000;
  padding: 12px;
  background-color: rgb(197, 197, 188);
}
</style>